




























































































































































































@import '@design';

.theme--BlueGiant .menu-layout--title {
  margin-bottom: 0.7em;
  font-size: 60px;
  font-weight: 700;
  line-height: 0.65;
  text-transform: uppercase;

  @media screen and (min-width: 960px) {
    margin-bottom: 1.1em;
    font-size: 75px;
  }
}

.menu-layout {
  .is-dirty {
    position: relative;
    &::after {
      position: absolute;
      bottom: 0;
      left: -7px;
      width: 5px;
      height: 100%;
      // bottom:-5px;
      // left:0;
      // width:100%;
      // height:5px;
      content: '';
      background-color: $color-db-orange;
    }
  }
}

.menu-sections {
  // TODO (ESS) : don't hardcode this.
  // this is to account for the px-3 applied on MenuSection
  margin-right: -16px;
  margin-left: -16px;
}

.masonry-wrap {
  // display:grid;
  // grid-template-columns:repeat( auto-fill, minmax( 50%, 1fr ) );
  // column-count:2;
}

.blue-giant--section-wrap {
  // display:inline-block;
  width: 50%;

  @media (max-width: 600px) {
    width: 100%;
  }
  // &:nth-child(odd) {
  //   float:right;
  // }
  // &:nth-child(even) {
  //   float:left;
  // }
}
